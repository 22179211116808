@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

/** Regular */
@font-face {
  font-family: 'SF Pro Display';
  font-weight: 400;
  src: url('./assets/fonts/SFProDisplay-Regular.woff');
}

/** Medium */
@font-face {
  font-family: 'SF Pro Display';
  font-weight: 500;
  src: url('./assets/fonts/SFProDisplay-Medium.woff');
}

/** Semi Bold */
@font-face {
  font-family: 'SF Pro Display';
  font-weight: 600;
  src: url('./assets/fonts/SFProDisplay-Semibold.woff');
}

/** Bold */
@font-face {
  font-family: 'SF Pro Display';
  font-weight: 700;
  src: url('./assets/fonts/SFProDisplay-Bold.woff');
}
